// React
import React from "react"

// Gatsby
import {
    graphql
} from "gatsby"

// Images
import Image from "gatsby-image"

// MDX
import MdxWrapper from "../components/mdx/MdxWrapper"

// Site components
import Layout from "../components/layout"
import SEO from "../components/seo"
import LinkExt from "../components/linkext"

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Styles
import "../styles/pages/SnowTubesPage.scss"

// List of videos
const videos = [
    {
        number: "1-1",
        topic: "MixtroidVania",
        title: "Project Setup & Settings",
        description: "In this video, we are going to set up our project and configure some basic settings!",
        ytVideoId: "1k_61yTu6sA",
        isLive: true,
    },
    {
        number: "1-2",
        topic: "MixtroidVania",
        title: "Layered Background Panels",
        description: "In this video, we are going to add background panels to our game and make sure they are drawn in the correct order!",
        // In this video, we are starting to have a look at our game’s visuals by adding multiple layers of background images and making sure they are drawn in the correct order!
        ytVideoId: "e7PNTLAlBeI",
        isLive: true,
    },
    {
        number: "1-3",
        topic: "MixtroidVania",
        title: "Level Building with Tilemaps",
        description: "In this video, we are going to... (coming soon)",
        //ytVideoId: "",
        isLive: false,
    },
    {
        number: "1-4",
        topic: "MixtroidVania",
        title: "More Tilemaps & Palettes",
        description: "In this video, we are going to... (coming soon)",
        //ytVideoId: "",
        isLive: false,
    },
    {
        number: "1-5",
        topic: "MixtroidVania",
        title: "Rule Tiles",
        description: "In this video, we are going to... (coming soon)",
        //ytVideoId: "",
        isLive: false,
    },
    {
        number: "1-6",
        topic: "MixtroidVania",
        title: "Random Tiles",
        description: "In this video, we are going to... (coming soon)",
        //ytVideoId: "",
        isLive: false,
    },    
    {
        number: "1-7",
        topic: "MixtroidVania",
        title: "Animated Tiles",
        description: "In this video, we are going to... (coming soon)",
        //ytVideoId: "",
        isLive: false,
    },    
];

// Component for the MixtroidVania page
const MixtroidVaniaPage = ({
    data,
    location
}) => {

    // MDX data for this page
    const pageData = data.mdx;

    // Frontmatter for this page
    const {
        title: pageTitle,
        category: pageCategory,
        // TODO: Replace this date in the md file with an automatic last updated date feature based on the file changed attribute ?
        date: pageDate,
        description: pageDescription,
    } = pageData.frontmatter;

    // Page thumbnail
    //let pageThumbnail = data.pageIllustration.childImageSharp.fluid;
    //let shareThumbnail = data.pageIllustration.childImageSharp.resize?.src;

    // Devlog thumbnails (imported via the graphql page query)
    const thumbnails = data.allFile.edges;

    // Render
    return (
        <Layout>

            {/* SEO */}
            <SEO
                title={pageTitle}
                description={pageDescription}
                pathname={location.pathname}
            // thumbnail={shareThumbnail}
            />

            {/* Page */}
            <article id="snowtubes-page">

                {/* Page header */}
                <header className="page-header page-header-with-thumbnail">

                    {/* Text on lhs */}
                    <section className="header-text">

                        {/* Page category */}
                        <div className="page-category">
                            {pageCategory}
                        </div>

                        {/* Page title */}
                        <h1 className="page-title">
                            {pageTitle}
                        </h1>

                        {/* Page description */}
                        <p className="page-description">
                            {pageDescription}
                        </p>

                    </section>

                    {/* Image on rhs */}
                    {/* <section className="page-image">
                        {pageThumbnail &&
                            <Image fluid={pageThumbnail} />
                        }
                    </section> */}

                </header>

                {/* Page main */}
                <main className="page-main">

                    {/* Page body */}
                    <section className="page-body">

                        {/* MDX */}
                        <MdxWrapper>
                            {pageData.body}
                        </MdxWrapper>

                    </section>

                    {/* Grid of videos */}
                    <section className="devlog-grid">

                        {videos.map((devlog, index) => (
                            <div
                                className="grid-item"
                                key={devlog.number}
                            >

                                <div className="item-thumbnail">
                                    {devlog.isLive
                                        ?
                                        <img 
                                            src={ `https://img.youtube.com/vi/${devlog.ytVideoId}/maxresdefault.jpg` } 
                                            alt="Episode thumbnail"
                                        />
                                        // <img 
                                        //     src={ `https://img.youtube.com/vi/${devlog.ytVideoId}/mqdefault.jpg` } 
                                        //     alt="Episode thumbnail"
                                        // />
                                        :
                                        <Image
                                            fluid={thumbnails[ index ].node.childImageSharp.fluid}
                                            alt="Episode thumbnail"
                                        />
                                    }
                                </div>

                                <div className="item-category">
                                    {devlog.topic} &middot;  Episode {devlog.number}
                                </div>
                                <div className="item-title">
                                    {devlog.title}
                                </div>
                                <div className="item-description">
                                    {devlog.description || "...."}
                                </div>

                                {devlog.isLive
                                    ?
                                    <LinkExt
                                        className="item-button-active"
                                        to={`https://www.youtube.com/watch?v=${devlog.ytVideoId}`}
                                    >
                                        <FontAwesomeIcon
                                            icon={[ "fab", "youtube" ]}
                                        />
                                        Watch on YouTube
                                    </LinkExt>
                                    :
                                    <div className="item-button-inactive">
                                        Coming Soon
                                    </div>
                                }

                            </div>
                        ))}

                    </section>

                </main>

                {/* Page footer */}
                <footer className="page-footer">

                    Last updated: {pageDate}

                </footer>

            </article>

        </Layout>
    )
}

// Export component
export default MixtroidVaniaPage

// GraphQL query
export const pageQuery = graphql`

    query MixtroidVaniaPageQuery {

        pageIllustration: file(
            absolutePath: {
                regex: "/homepage-illustration-snowtubes.png/"
            }
        ) {
            childImageSharp {
                fluid( maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                }
                resize(width: 1200, height: 1200) {
                    src
                }       
            }
        }              

        allFile(
            sort: { 
                fields: name,
                order: ASC
            }
            filter: { 
                relativeDirectory: { eq: "snowtubes" },
                extension: { eq: "png" }
            }
        ) {
            edges {
                node {
                    id
                    name
                    childImageSharp {
                        fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }

        mdx(
            fileAbsolutePath: {
                glob: "**/content/pages/**/mixtroidvania.mdx"
            }
        ) {
            excerpt(pruneLength: 160)
            body
            frontmatter {
                title
                category
                date(formatString: "MMM D, YYYY")
                description
            }      
        }         
        
    }
`
